import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BannerCarouselItemDto } from 'types';

import css from './BannerCarouselImageDescription.module.css';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    carouselImageDescriptionContainer: {
        // height: 'calc(100% - 40px)',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 36,
        bottom: 36,
        width: '100%',
    },
    carouselImageDescription: {
        [theme.breakpoints.down(768)]: {
            padding: '0 16px',
        },
        [theme.breakpoints.down(640)]: {
            fontSize: 32,
        },
        [theme.breakpoints.down(500)]: {
            fontSize: 24,
            lineHeight: 1.5,
        },
        [theme.breakpoints.down(400)]: {
            fontSize: 18,
            lineHeight: 1.5,
        },
    },
}));

export const BannerCarouselImageDescription = (props: { item: BannerCarouselItemDto }) => {
    const {
        item: { description, styles = {} },
    } = props;
    const classes = useStyles();
    return (
        <div className={classes.carouselImageDescriptionContainer}>
            <Typography
                style={styles}
                className={clsx(classes.carouselImageDescription, css.carouselImageDescription)}
                component={'p'}
            >
                {description}
            </Typography>
        </div>
    );
};
