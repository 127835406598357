import React from 'react';

import { StoreDeliveryDto, SupportedCountryCodes } from 'types';
import { getDeliveryTextMobile, getDeliveryTextDesktop } from './utils';

export interface DeliveryInfoProps {
    deliveries: StoreDeliveryDto[];
    isoCode?: SupportedCountryCodes;
}

export const DeliveryInfoMobile = (props: DeliveryInfoProps) => {
    const deliveryText = getDeliveryTextMobile(props.deliveries || []);
    return <span>{deliveryText}</span>;
};

export const DeliveryInfo = (props: DeliveryInfoProps) => {
    const deliveryText = getDeliveryTextDesktop(props.deliveries || [], props.isoCode);
    return <span>{deliveryText}</span>;
};
