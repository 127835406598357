import { BannerCarouselImage } from './BannerCarouselImage';
import { makeStyles, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { ReactComponent as BulletIcon } from 'assets/icons/main-16/bullet--16.svg';
import { ReactComponent as BackwardIcon } from 'assets/icons/main-16/chevron-big-left--16.svg';
import { ReactComponent as ForwardIcon } from 'assets/icons/main-16/chevron-big-right--16.svg';
import { ReactComponent as DotIcon } from 'assets/icons/main-16/dot--16.svg';
import classNames from 'classnames';
import Carousel from 'nuka-carousel';
import { useCallback, useEffect, useState } from 'react';
import { BannerCarouselItemDto } from 'types';

export interface BannerCarouselProps {
    carouselItems: BannerCarouselItemDto[];
    onBannerClick: (clickedCarouselItem: BannerCarouselItemDto) => void;
    className?: string;
}

enum CarouselArrowDirection {
    PREV = 'PREV',
    NEXT = 'NEXT',
}

interface Arrows {
    type: CarouselArrowDirection;
    onClick: () => void;
    classes: ClassNameMap;
}

interface CarouselDotsBlock {
    pages: number[];
    activePage: number;
    onClick: (indicatorId: number) => void;
    classes: ClassNameMap;
}

const useStyles = makeStyles(theme => ({
    carouselContainer: {
        height: 344,
        marginBottom: 8,
        backgroundColor: theme.palette.brandPrimary.lightest,
        '& .slider': {
            outline: 'none',
            height: 'inherit !important',
            '& .slider-frame': {
                height: 'inherit !important',
                '& .slider-list': {
                    height: 'inherit !important',
                    '& .slider-slide': {
                        height: 'inherit !important',
                    },
                },
            },
        },
        '& .slider-control-bottomcenter': {
            width: '100%',
            display: 'flex',
            padding: '0 16px',
            flexDirection: 'column',
            alignItems: 'center',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        [theme.breakpoints.down(480)]: {
            height: 176,
        },
    },
    arrowsVertical: {
        display: 'flex',
        alignItems: 'center',
    },
    arrowCircle: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        background: theme.palette.fixedWhite.light,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(768)]: {
            display: 'none',
        },
    },
    prevArrowContainer: {
        paddingLeft: 40,
    },
    nextArrowContainer: {
        paddingRight: 40,
    },
    dotsContainer: {
        height: 16,
        display: 'flex',
        width: '100%',
        marginBottom: 24,
        scrollbarWidth: 'none',
        overflowX: 'auto',
        justifyContent: 'center',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '& div': {
            margin: '0 3px',
        },
        [theme.breakpoints.down(480)]: {
            marginBottom: 16,
        },
    },
    carouselDotsColor: {
        '& path': {
            fill: '#FFFFFF',
        },
    },
    iconContainer: {
        height: 16,
        width: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    smallIconContainer: {
        height: 4,
        width: 4,
    },
}));

const CarouselArrow = ({ type, onClick, classes }: Arrows) => {
    let pointer;
    let arrowStyle;
    if (type === CarouselArrowDirection.PREV) {
        pointer = BackwardIcon;
        arrowStyle = classes.prevArrowContainer;
    } else {
        pointer = ForwardIcon;
        arrowStyle = classes.nextArrowContainer;
    }
    return (
        <div className={classNames(classes.arrowsVertical, arrowStyle)}>
            <div className={classes.arrowCircle} onClick={onClick}>
                <div className={classes.iconContainer}>
                    <SvgIcon className={classes.iconContainer} component={pointer} viewBox="0 0 16 16" />
                </div>
            </div>
        </div>
    );
};

const CarouselDots = ({ pages, activePage, onClick, classes }: CarouselDotsBlock) => {
    return (
        <div className={classes.dotsContainer}>
            {pages.map(page => {
                const isActivePage = activePage === page;
                return (
                    <div key={page} className={classes.iconContainer} onClick={() => onClick(page)}>
                        {isActivePage ? (
                            <SvgIcon
                                className={classNames(classes.iconContainer, classes.carouselDotsColor)}
                                component={DotIcon}
                                viewBox="0 0 16 16"
                            />
                        ) : (
                            <SvgIcon
                                className={classNames(classes.smallIconContainer, classes.carouselDotsColor)}
                                component={BulletIcon}
                                viewBox="0 0 4 4"
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export const BannerCarousel = (props: BannerCarouselProps) => {
    const { onBannerClick, carouselItems } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [carouselItemsFetched, setCarouselItemsFetched] = useState(false);
    const [isMultipleItemsFetched, setIsMultipleItemsFetched] = useState(false);
    const [carouselDotsCounter, setCarouselDotsCounter] = useState([]);

    const [breakpointMarker, setBreakpointMarker] = useState(null);
    const is1920UpScreen = useMediaQuery(theme.breakpoints.up(1920));
    const is1280Screen = useMediaQuery(theme.breakpoints.between(1280, 1920));
    const is960Screen = useMediaQuery(theme.breakpoints.between(960, 1280));
    const is768Screen = useMediaQuery(theme.breakpoints.between(768, 960));
    const is640Screen = useMediaQuery(theme.breakpoints.between(640, 768));
    const is480Screen = useMediaQuery(theme.breakpoints.between(480, 640));
    const is360Screen = useMediaQuery(theme.breakpoints.between(360, 480));
    const is360DownScreen = useMediaQuery(theme.breakpoints.down(360));

    useEffect(() => {
        switch (true) {
            case is1920UpScreen: {
                setBreakpointMarker(1920);
                break;
            }
            case is1280Screen: {
                setBreakpointMarker(1280);
                break;
            }
            case is960Screen: {
                setBreakpointMarker(960);
                break;
            }
            case is768Screen: {
                setBreakpointMarker(768);
                break;
            }
            case is640Screen: {
                setBreakpointMarker(640);
                break;
            }
            case is480Screen: {
                setBreakpointMarker(480);
                break;
            }
            case is360Screen:
                setBreakpointMarker(360);
                break;
            case is360DownScreen: {
                setBreakpointMarker(360);
                break;
            }
        }
    }, [
        is1920UpScreen,
        is1280Screen,
        is960Screen,
        is768Screen,
        is640Screen,
        is480Screen,
        is360Screen,
        is360DownScreen,
        setBreakpointMarker,
    ]);

    useEffect(() => {
        const length = carouselItems.length;
        if (length > 0) {
            setCarouselItemsFetched(true);
            setCarouselDotsCounter(carouselItems.map((_, index) => index));
            if (length > 1) {
                setIsMultipleItemsFetched(true);
            } else {
                setIsMultipleItemsFetched(false);
            }
        } else {
            setCarouselItemsFetched(false);
            setIsMultipleItemsFetched(false);
        }
    }, [carouselItems, setCarouselItemsFetched]);

    const prevArrow = useCallback(
        ({ previousSlide }) => (
            <CarouselArrow type={CarouselArrowDirection.PREV} onClick={previousSlide} classes={classes} />
        ),
        [],
    );

    const nextArrow = useCallback(
        ({ nextSlide }) => <CarouselArrow type={CarouselArrowDirection.NEXT} onClick={nextSlide} classes={classes} />,
        [],
    );

    const dotsContainer = useCallback(
        ({ currentSlide, goToSlide }) => {
            return (
                <CarouselDots
                    pages={carouselDotsCounter}
                    activePage={currentSlide}
                    onClick={goToSlide}
                    classes={classes}
                />
            );
        },
        [carouselDotsCounter],
    );

    const emptyControl = useCallback(() => null, []);

    return (
        carouselItemsFetched && (
            <div>
                <div className={classNames(classes.carouselContainer, props.className)}>
                    <Carousel
                        wrapAround={true}
                        pauseOnHover={true}
                        autoplay={isMultipleItemsFetched}
                        autoplayInterval={5000}
                        swiping={isMultipleItemsFetched}
                        renderCenterLeftControls={isMultipleItemsFetched ? prevArrow : emptyControl}
                        renderCenterRightControls={isMultipleItemsFetched ? nextArrow : emptyControl}
                        renderBottomCenterControls={isMultipleItemsFetched ? dotsContainer : emptyControl}
                    >
                        {carouselItems.map((item, index) => (
                            <BannerCarouselImage
                                key={index}
                                item={item}
                                breakpointMarker={breakpointMarker}
                                onBannerClick={onBannerClick}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
        )
    );
};
