import DefaultStoreItemImage from 'images/DefaultImageStore.webp';
import React, { useCallback, useMemo } from 'react';
import { Endpoint } from 'Endpoint';
import { makeStyles } from '@material-ui/core/styles';
import { makeUrl } from 'core/utils/utils';
import { BannerCarouselImageDescription } from './BannerCarouselImageDescription';
import { BannerCarouselItemDto } from 'types';
import Image from 'next/image';

export interface BannerCarouselImageProps {
    item: BannerCarouselItemDto;
    breakpointMarker: number;
    onBannerClick: (item: BannerCarouselItemDto) => void;
}

const useStyles = makeStyles({
    placeholder: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${DefaultStoreItemImage})`,
        width: '100%',
        height: '100%',
    },
    carouselImage: {
        width: '100%',
        height: '100%',
        flex: 1,

        '& div': {
            height: '100%'
        }
    },
});

const sanityIoImageLoader = ({ src, width }: any) => {
    return `${src}?img_v=${width}`
}

export const BannerCarouselImage = (props: BannerCarouselImageProps) => {
    const { breakpointMarker, item, onBannerClick } = props;
    const classes = useStyles();

    const imageId = useMemo(() => item?.images?.[breakpointMarker], [breakpointMarker, item]);
    const src = makeUrl(Endpoint.IMAGE_BY_ID, { imageId });

    const handleBannerClick = useCallback(() => {
        onBannerClick(item);
    }, [onBannerClick, item]);
    return (
        <div className={classes.carouselImage} onClick={handleBannerClick}>
            {imageId ? (
                <Image
                    title={item.description}
                    src={src}
                    loader={sanityIoImageLoader}
                    alt={item.description}
                    layout={'responsive'}
                    width={'100%'}
                    height={'100%'}
                    objectFit={'cover'}
                    loading={'lazy'}
                />
            ) : (
                <div title={item.description} className={classes.placeholder} />
            )}
            {!!item.description && <BannerCarouselImageDescription item={item} />}
        </div>
    );
};
