import React, { useMemo } from 'react';
import round from 'lodash/round';
import i18n from '../../../i18n';
import browserCookies from 'browser-cookies';
import { SupportedCountryCodes } from '../../types';

const isServer = typeof window === 'undefined';

export interface DistanceProps {
    meters: number;
}

export const Distance = (props: DistanceProps) => {
    const { t } = i18n.useTranslation();
    const isoCode = !isServer ? browserCookies?.get('country') : SupportedCountryCodes.RUS;
    const meters = props.meters ?? 0;
    const fullText = useMemo(() => {
        const distance = isoCode === SupportedCountryCodes.USA ? round(meters / 1609, 1) : round(meters / 1e3, 1);
        const text =
            isoCode === SupportedCountryCodes.USA ? t('common:enums.DISTANCE.MILES') : t('common:enums.DISTANCE.KM');
        return `${distance} ${text}`;
    }, [isoCode, meters]);
    return <span>{fullText}</span>;
};
